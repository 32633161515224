<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <div class="wp-100 align-center">
        <div class="headerContainer">
          <div class="badgeLogoContainer">
            <img class="badgeLogo" :src="badgeLogo" @click="goToDashBoard()" />
          </div>
          <div class="avatarContainer" @click="changeAvatar()">
            <img class="avatar" :src="getProfilePhoto()" />
          </div>
          <input
            type="file"
            ref="file"
            accept="image/*"
            class="hidden"
            @input="(e) => handleFile(e)"
          />
        </div>
        <div class="greetingsContainer">
          <p class="greetingsText">{{ $t("labels.changePhoto") }}</p>
        </div>
      </div>
      <div class="mainContainer">
        <div class="subContainer">
          <h1 class="title">
            {{ $t("labels.editMyProfile") }}
          </h1>
          <div class="inputRow">
            <custom-input
              type="text"
              :modelValue="name"
              :modelInvalid="invalidName"
              @update:modelValue="name = $event"
              @update:modelInvalid="invalidName = $event"
              :placeholder="`* ${$t('labels.name')}`"
            />
          </div>
          <div class="inputRow">
            <custom-input
              type="text"
              :modelValue="city"
              :modelInvalid="invalidCity"
              @update:modelValue="city = $event"
              @update:modelInvalid="invalidCity = $event"
              :placeholder="`* ${$t('labels.city')}`"
              className="wp-48"
            />
            <drop-down
              :modelInvalid="invalidCountry"
              :modelValue="country"
              @update:modelValue="country = $event"
              @update:modelInvalid="invalidCountry = $event"
              input-class="text-center"
              :options="countries"
              :placeholder="`* ${$t('labels.country')}`"
              :className="`wp-48 ${invalidCountry ? 'invalid' : 'valid'}`"
            />
          </div>
          <div class="inputRow">
            <custom-date-picker-input
              :modelValue="birthday"
              :modelInvalid="invalidBirthday"
              @update:modelValue="birthday = $event"
              @update:modelInvalid="invalidBirthday = $event"
              @save="$emit('save')"
              @click.stop=""
            />
            <drop-down
              input-class="text-center"
              :modelInvalid="invalidGender"
              :modelValue="gender"
              @update:modelValue="gender = $event"
              @update:modelInvalid="invalidGender = $event"
              :options="genders"
              :placeholder="`* ${$t('labels.gender')}`"
              :className="`wp-48 ${invalidGender ? 'invalid' : 'valid'}`"
            />
          </div>
          <div class="inputRow">
            <custom-input
              type="text"
              :modelInvalid="invalidMobile"
              :modelValue="mobile"
              @update:modelValue="mobile = $event"
              @update:modelInvalid="invalidMobile = $event"
              :placeholder="`* ${$t('labels.phoneNumber')}`"
              className="wp-48"
            />
            <drop-down
              :modelInvalid="inavlidLanguage"
              :modelValue="language"
              @update:modelValue="language = $event"
              @update:modelInvalid="inavlidLanguage = $event"
              input-class="text-center"
              :options="langData"
              :placeholder="`* ${$t('labels.language')}`"
              :className="`wp-48 ${inavlidLanguage ? 'invalid' : 'valid'}`"
            />
          </div>
          <button class="authBtnContainer bg-yellow" @click="update()">
            {{ $t("labels.update") }}
          </button>
          <button class="authBtnContainer bg-darkgray" @click="logout()">
            {{ $t("labels.logout") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { ref } from "vue";
import CustomInput from "../components/CustomInput.vue";
import CustomDatePickerInput from "../components/CustomDatePickerInput.vue";
import DropDown from "../components/DropDown.vue";
import { mapState } from "vuex";
export default {
  name: "ProfilePage",
  components: {
    CustomInput,
    CustomDatePickerInput,
    DropDown,
  },
  data() {
    return {
      logo: require("../assets/image/svg/logo.svg"),
      avatar: require("../assets/image/png/avatarPlaceholer.png"),
      badgeLogo: require("../assets/image/svg/badgeLogo.svg"),
      selectedImage: null,
      selectedImageFile: null,
      name: "",
      invalidName: false,
      birthday: "",
      invalidBirthday: false,
      gender: "",
      city: "",
      invalidCity: false,
      country: "",
      invalidCountry: false,
      language: "",
      inavlidLanguage: false,
      mobile: "",
      invalidMobile: false,
      invalidGender: false,
      genders: [
        {
          value: "1",
          label: "Chico",
        },
        {
          value: "2",
          label: "Chica",
        },
        {
          value: "3",
          label: "Otro",
        },
      ],
      langData: [
        {
          value: "es",
          label: "Español",
        },
        {
          value: "en",
          label: "English",
        },
      ],
    };
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToDashBoard() {
      this.$router.push("/dashboard");
    },
    getProfilePhoto() {
      if (this.selectedImage) {
        return `${this.selectedImage}`;
      }
      if (this.oldImage) {
        return `data:image/png;base64,${this.oldImage}`;
      } else {
        return this.avatar;
      }
    },
    changeAvatar() {
      this.$refs.file.click();
    },
    getCountry() {
      if (!this.countries || this.countries.length == 0) {
        return null;
      }
      if (!this.auth.country) {
        return null;
      }
      let countryObj;
      for (let i = 0; i < this.countries.length; i++) {
        let item = this.countries[i];
        if (item.value == this.auth.country) {
          countryObj = item;
          break;
        }
      }
      return countryObj;
    },
    handleFile(e) {
      let files = e.target.files;
      if (files && files[0]) {
        this.selectedImageFile = files[0];
        let reader = new FileReader();
        reader.onload = (evt) => {
          this.selectedImage = evt.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
    },
    update() {
      let flag = false;
      if (!this.name) {
        this.invalidName = true;
        flag = true;
      }
      if (!this.city) {
        this.invalidCity = true;
        flag = true;
      }
      if (!this.country) {
        this.invalidCountry = true;
        flag = true;
      }
      if (!this.birthday) {
        this.invalidBirthday = true;
        flag = true;
      }
      if (!this.gender) {
        this.invalidGender = true;
        flag = true;
      }
      if (!this.mobile) {
        this.invalidMobile = true;
        flag = true;
      }
      if (!this.language) {
        this.inavlidLanguage = true;
        flag = true;
      }
      if (flag) return;
      let user = {
        name: this.name,
        country: this.country,
        birthday: this.birthday,
        gender: this.gender,
        mobile: this.mobile,
        city: this.city,
        image: this.selectedImageFile,
        language: this.language,
      };
      this.$store.dispatch("auth/updateProfileAttempt", user);
    },
    logout() {
      this.$store.dispatch("auth/logout");
    }
  },
  watch: {
    oldBirthday: {
      handler(newVal) {
        this.birthday = new Date(newVal);
        this.$emit("save");
      },
      immediate: true,
    },
    oldName: {
      handler(newVal) {
        this.name = newVal;
      },
      immediate: true,
    },
    oldCity: {
      handler(newVal) {
        this.city = newVal;
      },
      immediate: true,
    },
    oldCountry: {
      handler(newVal) {
        this.country = newVal;
      },
      immediate: true,
    },
    oldGender: {
      handler(newVal) {
        this.gender = newVal;
      },
      immediate: true,
    },
    oldImage: {
      handler(newVal) {
        this.image = newVal;
      },
      immediate: true,
    },
    oldMobile: {
      handler(newVal) {
        this.mobile = newVal;
      },
      immediate: true,
    },
    oldLanguage: {
      handler(newVal) {
        this.language = newVal;
        this.$root.$i18n.locale = newVal;
      },
      immediate: true,
    },
  },

  computed: mapState({
    countries: (state) => state.main.countries,
    oldName: (state) => state.auth.name,
    oldCity: (state) => state.auth.city,
    oldCountry: (state) => state.auth.country,
    oldBirthday: (state) => state.auth.birthday,
    oldGender: (state) => state.auth.gender,
    oldMobile: (state) => state.auth.mobile,
    oldImage: (state) => state.auth.image,
    oldLanguage: (state) => state.auth.language,
  }),
  mounted() {

    //this.$store.dispatch("auth/getProfileAttempt");
   // this.$store.dispatch("main/getCountries");
  },
};
</script>

<style scoped>
.termsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.termsDes {
  font-size: 13px;
  color: var(--textColor);
}
.termsBtn {
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.no-border {
  border: none;
}
.hidden {
  visibility: hidden;
  display: none;
}
</style>